<template>
    <layout>
        <page-header :title="title" :items="items" />

        <awesome-table
            id="users"
            :labels="labels"
            :items="users"
            :options="{ sort: true, filters: true, columnsSelector: true }"
            :pagination="pagination"
            :filters="filters"
            @add-button-click="$bvModal.show('modalUserCreateEdit')"
            @row-click="onRowClick"
            @pagination-change="onPaginationChange"
            @filter-change="onFilterChange"
        >
            <template #[`items.enable_order_notifications`]="{ item }">
                <span
                    v-if="item.enable_order_notifications"
                    class="badge bg-soft-success text-success"
                >
                    Enabled
                </span>
                <span v-else class="badge bg-soft-danger text-danger">
                    Disabled
                </span>
            </template>
            <template #[`items.actions`]="{ item }">
                <a
                    v-if="!isHeadAdmin(item.roles)"
                    href="#"
                    class="action-icon"
                    @click.stop.prevent="onDeleteUser(item)"
                >
                    <i class="far fa-trash-alt" />
                </a>
            </template>
        </awesome-table>

        <modal-user-create-edit
            :modal-title="userToUpdate ? 'Edit User' : 'Add New User'"
            :button-text="userToUpdate ? 'Update' : 'Save'"
            :updating-user="!!userToUpdate"
            :user="userToUpdate"
            @hidden="hiddenModalUserCreteEditHandler"
            @createUser="createUserHandler"
            @updateUser="updateUserHandler"
        />
    </layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import appConfig from '@src/app.config';
import ModalUserCreateEdit from '@components/modals/user/ModalUserCreateEdit';

export default {
    page: {
        title: 'Users',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    components: {
        ModalUserCreateEdit
    },

    data() {
        const {
            page: currentPage = 1,
            perPage = 10,
            sortBy = 'created_at',
            order = 'DESC',
            q = null,
            filters = '{}'
        } = this.$route.query;

        return {
            userToUpdate: null,
            title: 'Users',
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Users',
                    active: true
                }
            ],
            users: [],

            labels: [
                'first_name',
                'last_name',
                'email',
                'phone',
                { value: 'enable_order_notifications', type: 'boolean' },
                {
                    value: 'created_at',
                    filter: 'formatDate',
                    type: 'date'
                },
                { value: 'actions', sortable: false, filterable: false }
            ],
            pagination: {
                currentPage: parseInt(currentPage),
                perPage: parseInt(perPage),
                total: 0,
                search: q || '',
                sortBy,
                descending: order.toLowerCase() === 'desc'
            },
            filters: JSON.parse(filters),
        };
    },

    computed: {
        ...mapGetters({
            isHeadAdmin: 'roles/isHeadAdmin'
        })
    },

    mounted() {
        this.fetchUsers();
    },

    methods: {
        ...mapActions({
            getUsers: 'users/index',
            createUser: 'users/create',
            updateUser: 'users/update',
            deleteUser: 'users/delete'
        }),

        async fetchUsers() {
            const options = {
                ...this.pagination,
                filters: this.filters
            };

            const { rows, count } = await this.getUsers(options);

            if (options.currentPage !== this.pagination.currentPage) {
                return;
            }

            this.users = rows;
            this.pagination.total = count;
            this.pagination.pages = Math.ceil(
                this.pagination.total / this.pagination.perPage
            );
        },

        hiddenModalUserCreteEditHandler() {
            this.userToUpdate = null;
        },

        onRowClick(user) {
            const {
                acl: { permissions },
                ...data
            } = user;

            this.userToUpdate = { ...data, permissions };

            this.$bvModal.show('modalUserCreateEdit');
        },

        async createUserHandler(data) {
            try {
                const response = await this.createUser(data);
                this.users = [response, ...this.users];

                this.$toaster('User has been added!');
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        async updateUserHandler(data) {
            try {
                const response = await this.updateUser(data);
                const filteredUsers = this.users.filter(
                    item => item.id !== response.id
                );
                this.users = [...filteredUsers, response];

                this.$toaster('User has been updated!');
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        async onDeleteUser(user) {
            const confirmed = await this.$confirmationModal(
                `Are you sure you want to delete this user - ${
                    user.first_name
                } ${user.last_name}?`
            );

            if (!confirmed) {
                return;
            }

            try {
                await this.deleteUser(user);
                this.users = this.users.filter(item => item.id !== user.id);

                this.$toaster('User has been removed!');
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        onPaginationChange(pagination) {
            this.pagination = {
                ...pagination
            };

            this.fetchUsers();
        },

        onFilterChange(filters) {
            this.filters = { ...filters };
            this.pagination.currentPage = 1;

            this.fetchUsers();
        }
    }
};
</script>
