<template>
    <b-modal
        id="modalUserCreateEdit"
        class="modal-demo"
        title-class="text-light"
        header-bg-variant="dark"
        header-text-variant="light"
        title-tag="h4"
        size="lg"
        centered
        @show="openModalHandler"
        @hidden="hideModal"
    >
        <template slot="modal-title">
            {{ modalTitle }}
        </template>
        <div class="custom-modal-text text-left">
            <div>
                <div class="form-group">
                    <label for="category">Email</label>
                    <input
                        id="category"
                        v-model="data.email"
                        :class="{ 'is-invalid': $v.data.email.$error }"
                        type="text"
                        class="form-control"
                        placeholder="Enter Email"
                    />
                </div>
                <div class="form-group">
                    <label for="first_name">First Name</label>
                    <input
                        id="first_name"
                        v-model="data.first_name"
                        :class="{ 'is-invalid': $v.data.first_name.$error }"
                        type="text"
                        class="form-control"
                        placeholder="Enter first name"
                    />
                </div>
                <div class="form-group">
                    <label for="last_name">Last Name</label>
                    <input
                        id="last_name"
                        v-model="data.last_name"
                        :class="{ 'is-invalid': $v.data.last_name.$error }"
                        type="text"
                        class="form-control"
                        placeholder="Last Name"
                    />
                </div>
                <div class="form-group">
                    <label for="phone">Phone</label>
                    <input
                        id="phone"
                        v-model="data.phone"
                        type="text"
                        class="form-control"
                        placeholder="Enter phone number"
                    />
                </div>
                <div
                    v-if="data.permissions && (data.permissions.all || data.permissions.designer)"
                    class="form-group"
                >
                    <label for="designer_id">Designer Id</label>
                    <input
                        id="designer_id"
                        v-model="data.designer_id"
                        type="text"
                        class="form-control"
                        placeholder="Enter designer id"
                    />
                </div>
                <div class="form-group">
                    <b-form-checkbox
                        id="order_notifications"
                        v-model="data.enable_order_notifications"
                        name="order_notifications"
                        class="checkbox-circle"
                    >
                        Enable Order Notifications
                    </b-form-checkbox>
                </div>
                <permissions-selector
                    v-if="data.permissions"
                    v-model="data.permissions"
                    :is-head-admin="isUserHeadAdmin"
                    :role="data.role"
                    class="form-group"
                />
            </div>
        </div>

        <template #modal-footer>
            <div class="col-12 d-flex justify-content-between">
                <button
                    type="button"
                    class="btn btn-danger waves-effect waves-light mr-2"
                    @click="hideModal"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    class="btn btn-success waves-effect waves-light"
                    @click="submitUserDataHandler"
                >
                    {{ buttonText }}
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, minLength, email } from 'vuelidate/lib/validators';
import PermissionsSelector from './PermissionsSelector';

export default {
    components: {
        PermissionsSelector
    },

    props: {
        updatingUser: {
            type: Boolean,
            required: false,
            default: false
        },
        user: {
            type: Object,
            required: false,
            default: null
        },
        modalTitle: {
            type: String,
            required: false,
            default: 'Add New Customer'
        },
        buttonText: {
            type: String,
            required: false,
            default: 'Save'
        }
    },

    data() {
        return {
            data: {}
        };
    },

    computed: {
        ...mapGetters({
            isHeadAdmin: 'roles/isHeadAdmin'
        }),

        isUserHeadAdmin() {
            if (this.data.roles) {
                return this.isHeadAdmin(this.data.roles);
            }

            return false;
        }
    },

    validations: {
        data: {
            email: {
                required,
                email
            },
            first_name: {
                required,
                minLength: minLength(4)
            },
            last_name: {
                required,
                between: minLength(4)
            }
        }
    },

    methods: {
        hideModal() {
            this.$bvModal.hide('modalUserCreateEdit');
            this.$emit('hidden');
            this.data = {};
        },

        async openModalHandler() {
            await this.$nextTick();

            if (this.user && this.updatingUser) {
                this.data = { ...this.user };
            } else {
                this.data = {
                    permissions: {}
                };
            }
        },

        submitUserDataHandler() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            if (this.updatingUser) {
                this.$emit('updateUser', this.data);
            } else {
                this.$emit('createUser', this.data);
            }

            this.$v.$reset();
            this.hideModal();
        }
    }
};
</script>
